<template>
    <div class="project_form">
        <div class="project_form__wrapper">
            <h2>Внесение изменений</h2>
            <a-form-model
                v-if="actionInfo"
                ref="investForm"
                :model="form">
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Основная информация о проекте</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.is_new" 
                            ref="is_new" 
                            label="Стадия проекта" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="is_new">
                            <a-select v-model="form.is_new" size="large" class="w-full">
                                <a-select-option value="true">
                                    Новый
                                </a-select-option>
                                <a-select-option value="false">
                                    Расширение
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item  
                            v-if="actionInfo.installation_stage" 
                            ref="installation_stage" 
                            label="Стадия СМР, %" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="installation_stage">
                            <a-input-number
                                v-model="form.installation_stage"
                                class="w-full"
                                :min="0"
                                :max="100"
                                placeholder="Стадия СМР, %"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.project_capacity" 
                            ref="project_capacity" 
                            label="Мощность проекта" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="project_capacity">
                            <a-input-number
                                v-model="form.project_capacity"
                                :step="0.01"
                                class="w-full"
                                placeholder="Укажите мощность проекта"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.measure_unit" 
                            ref="measure_unit" 
                            label="Единица измерения" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="measure_unit">
                            <a-select 
                                v-model="form.measure_unit"
                                placeholder="Единица измерения" 
                                size="large" 
                                class="w-full"
                                :default-active-first-option="false"
                                :not-found-content="null"
                                :loading="measureUnitLoading">
                                <a-select-option 
                                    v-for="unit in measureUnitList.results" 
                                    :key="unit.id" 
                                    :value="unit.code">
                                    {{ unit.string_view }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                </div>
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Стоимость проекта</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.funds" 
                            ref="funds" 
                            label="Вся стоимость проекта, млн. тенге" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="funds">
                            <a-input-number
                                v-model="form.funds"
                                :step="0.01"
                                class="w-full"
                                placeholder="Вся стоимость проекта"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.own_funds" 
                            ref="own_funds" 
                            label="Собственные средства, млн. тенге" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="own_funds">
                            <a-input-number
                                v-model="form.own_funds"
                                :step="0.01"
                                class="w-full"
                                placeholder="Собственные средства"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.borrowed_funds" 
                            ref="borrowed_funds" 
                            label="Заемные (АКК, БРК, СПК), млн. тенге" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="borrowed_funds">
                            <a-input-number
                                v-model="form.borrowed_funds"
                                :step="0.01"
                                class="w-full"
                                placeholder="Заемные (АКК, БРК, СПК)"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.bank_funds" 
                            ref="bank_funds" 
                            label="Банки второго уровня, млн. тенге" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="bank_funds">
                            <a-input-number
                                v-model="form.bank_funds"
                                :step="0.01"
                                class="w-full"
                                placeholder="Банки второго уровня"
                                size="large" />
                        </a-form-model-item>
                    </div>
                </div>
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Рабочие места</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.jobs_temporary" 
                            ref="jobs_temporary" 
                            label="Временные" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="jobs_temporary">
                            <a-input-number
                                v-model="form.jobs_temporary"
                                class="w-full"
                                placeholder="Укажите количество временных рабочих мест"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.jobs_permanent" 
                            ref="jobs_permanent" 
                            label="Постоянные" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="jobs_permanent">
                            <a-input-number
                                v-model="form.jobs_permanent"
                                class="w-full"
                                placeholder="Укажите количество постоянных рабочих мест"
                                size="large" />
                        </a-form-model-item>
                    </div>
                </div>
                <div v-if="actionInfo.equipment_purchases" class="form_block">
                    <div class="form_block__header">
                        <h3>Закуп оборудования</h3>
                    </div>
                    <div 
                        v-for="(equipment, index) in form.equipment_purchases" 
                        :key="equipment.key"
                        :class="form.equipment_purchases.length > 1 && 'mb-3'">
                        <div class="equipment_header flex items-center justify-between">
                            <div class="e_label">Источник закупа</div>
                            <div v-if="form.equipment_purchases.length > 1">
                                <a-button type="link" size="small" @click="removeEquipment(index)">
                                    Удалить
                                </a-button>
                            </div>
                        </div>
                        <div class="grid gap-4 md:gap-0 xl:gap-6 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                            <div>
                                <a-form-model-item 
                                    label="Страна закупа оборудования" 
                                    :prop="'equipment_purchases.' + index + '.country'"
                                    :rules="{
                                        required: true,
                                        message: $t('wgr.field_require'),
                                        trigger: 'blur',
                                    }">
                                    <a-select 
                                        v-model="equipment.country" 
                                        placeholder="Укажите страну закупа оборудование" 
                                        size="large" 
                                        class="w-full"
                                        :default-active-first-option="false"
                                        :not-found-content="null"
                                        show-search
                                        :filter-option="filterOption"
                                        :loading="countryLoading">
                                        <a-select-option 
                                            v-for="iCountry in countryList.results" 
                                            :key="iCountry.id" 
                                            :value="iCountry.id">
                                            {{ iCountry.string_view }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                                <a-form-model-item 
                                    :prop="'equipment_purchases.' + index + '.delivery_date'" 
                                    label="Сроки поставки оборудования (месяц, год)"
                                    :rules="{
                                        required: true,
                                        message: $t('wgr.field_require'),
                                        trigger: 'blur',
                                    }">
                                    <a-date-picker 
                                        v-model="equipment.delivery_date"
                                        :showToday="false" 
                                        size="large" 
                                        valueFormat="YYYY-MM-DD"
                                        placeholder="Сроки поставки оборудования" 
                                        class="w-full" />
                                </a-form-model-item>
                            </div>
                            <a-form-model-item 
                                :prop="'equipment_purchases.' + index + '.supplier_name'"  
                                label="Наименование планируемого поставщика"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-textarea
                                    v-model="equipment.supplier_name"
                                    class="equipment_textarea"
                                    placeholder="Наименование планируемого поставщика"
                                    :auto-size="{ minRows: 6, maxRows: 6 }" />
                            </a-form-model-item>
                        </div>
                    </div>
                    <a-button type="primary" ghost block size="large" class="mt-1 mb-5" @click="addEquipment()">
                        Добавить источник закупа
                    </a-button>
                </div>
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Примечание</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1">
                        <a-form-model-item v-if="actionInfo.work_experience" ref="work_experience" label="Опыт работы" prop="work_experience">
                            <a-textarea
                                v-model="form.work_experience"
                                :maxLength="1000"
                                :auto-size="{ minRows: 3, maxRows: 8 }"
                                placeholder="Укажите опыт работы (при наличии)"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1">
                        <a-form-model-item v-if="actionInfo.questions" ref="questions" label="Проблемные вопросы" prop="questions">
                            <a-textarea
                                v-model="form.questions"
                                :maxLength="1000"
                                :auto-size="{ minRows: 3, maxRows: 8 }"
                                placeholder="Опишите проблемные вопросы (при наличии)"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <a-button type="primary" :loading="loading" block size="large" @click="formSubmit()">
                        Сохранить изменения
                    </a-button>
                </div>
            </a-form-model>
            <a-skeleton v-else active />
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
const formData = {
    is_new: "true",
    installation_stage: '',
    project_capacity: '',
    measure_unit: null,
    funds: '',
    own_funds: '',
    work_experience: '',
    bank_funds: '',
    jobs_temporary: '',
    jobs_permanent: '',
    questions: '',
    equipment_purchases: [
        {
            key: Date.now(),
            country: null,
            supplier_name: '',
            delivery_date: null
        }
    ]
}

export default {
    props: {
        project: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            countryLoading: false,
            loading: false,
            form: {...formData},
            measureUnitLoading: false,
            actionInfo: null,
            measureUnitList: {
                results: []
            },
            countryList: {
                results: []
            }
        }
    },
    created() {
        this.getInvestActions()
        this.getMeasureUnit()
        this.getCountryList()
        const formInit = {...this.project}
        if(formInit.equipment_purchases?.length) {
            formInit.equipment_purchases = formInit.equipment_purchases.map(equipment => {
                return {
                    ...equipment,
                    key: equipment.id,
                    delivery_date: this.$moment(equipment.delivery_date),
                    country: equipment.country.id
                }
            })
        }
        if(formInit.is_new) {
            formInit.is_new = 'true'
        } else {
            formInit.is_new = 'false'
        }

        if(formInit.measure_unit?.code)
            formInit.measure_unit = formInit.measure_unit.code
        this.form = formInit
    },
    methods: {
        async getInvestActions() {
            try{
                const { data } = await this.$http.get(`/invest_projects_info/${this.$route.params.id}/action_info/`)
                if(data?.edit.length) {
                    const object = {}
                    data.edit.forEach(item => {
                        object[item] = true
                    })
                    this.actionInfo = object
                }
            } catch(e) {
                console.log(e)
            }
        },
        async getMeasureUnit() {
            if(!this.measureUnitList.results.length) {
                try {
                    this.measureUnitLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectMeasureUnitModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.measureUnitList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.measureUnitLoading = false
                }
            }
        },
        async getCountryList() {
            if(!this.countryList.results.length) {
                try {
                    this.countryLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectCountryModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.countryList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.countryLoading = false
                }
            }
        },
        removeEquipment(index) {
            this.form.equipment_purchases.splice(index, 1)
        },
        addEquipment() {
            this.form.equipment_purchases.push({
                country: null,
                supplier_name: '',
                delivery_date: null,
                key: Date.now(),
            })
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        formSubmit() {
            this.$refs['investForm'].validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {...this.form}
                        if(formData.is_new === 'true') {
                            formData.is_new = true
                        } else {
                            formData.is_new = false
                        }
                        if(formData.has_documentation === 'true') {
                            formData.has_documentation = true
                        } else {
                            formData.has_documentation = false
                        }
                        if(formData.direction?.code) {
                            formData.direction = formData.direction.code
                        }
                        if(formData.industry?.id) {
                            formData.industry = formData.industry.id
                        }
                        if(formData.district?.id) {
                            formData.district = formData.district.id
                        }
                        if(formData.organization?.id) {
                            formData.organization = formData.organization.id
                        }
                        if(formData.equipment_purchases?.length) {
                            formData.equipment_purchases = formData.equipment_purchases.map(item => {
                                return {
                                    ...item,
                                    delivery_date: this.$moment(item.delivery_date).format('YYYY-MM-DD')
                                }
                            })
                        }
                        delete formData.author

                        const { data } = await this.$http.put(`/invest_projects_info/${formData.id}/`, formData)
                        if(data) {
                            this.$message.success('Проект успешно обновлен')
                            eventBus.$emit('update_invest_full_project', data)
                        }
                    } catch(error) {
                        console.log(error)
                        this.$message.error('Ошибка сохранения')
                    } finally{
                        this.loading = false
                    }
                } else
                    return false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.project_form{
    .equipment_header{
        margin-bottom: 10px;
        .e_label{
            font-size: 16px;
            color: #000;
        }
    }
    .equipment_textarea{
        height: 80px!important;
        min-height: 80px!important;
        max-height: 80px!important;
        @media (min-width: 1280px) {
            height: 130px!important;
            min-height: 130px!important;
            max-height: 130px!important;
        }
    }
    &__wrapper{
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        padding: 15px;
        @media (max-width: 767px) {
            background: #fff;
        }
        @media (min-width: 768px) {
            padding: 20px;
        }
        @media (min-width: 1700px) {
            padding: 30px;
        }
        h2{
            font-size: 22px;
            color: #000;
            margin-bottom: 15px;
            margin-top: 0px;
            @media (min-width: 768px) {
                margin-bottom: 30px;
                font-size: 32px;
            }
        }
    }
    .form_block{
        &:not(:last-child){
            margin-bottom: 20px;
            border-bottom: 1px solid var(--border2);
            padding-bottom: 10px;
        }
        &__header{
            margin-bottom: 10px;
            h3{
                font-size: 20px;
                margin: 0px;
                font-weight: 400;
            }
        }
    }
}
</style>