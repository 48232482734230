<template>
    <div class="aside">
        <template v-if="project">
            <div class="flex items-center mb-4 aside_status flex-wrap">
                <a-button :type="project.is_new ?  'success' : 'primary'" ghost :size="isMobile ? 'default' : 'large'">
                    Создание
                </a-button>
                <a-button :type="project.has_documentation ?  'success' : 'primary'" ghost :size="isMobile ? 'default' : 'large'">
                    ПСД разработана
                </a-button>
                <a-button :type="project.infrastructure_info ?  'success' : 'primary'" ghost :size="isMobile ? 'default' : 'large'">
                    Проведена инфраструктура
                </a-button>
            </div>
            <div class="aside__block">
                <div class="aside_label">Основная информация о проекте</div>
                <div class="block_list">
                    <div v-if="project.district" class="block_list__item">
                        <div class="name">Регион проекта:</div>
                        <div class="value">{{ project.region.name }} - {{ project.district.name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Наименование предприятия:</div>
                        <div class="value">{{ project.company_name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">БИН предприятия:</div>
                        <div class="value">{{ project.company_bin }}</div>
                    </div>
                    <div v-if="project.direction" class="block_list__item">
                        <div class="name">Направление:</div>
                        <div class="value">{{ project.direction.name }}</div>
                    </div>
                    <div v-if="project.industry" class="block_list__item">
                        <div class="name">Отрасль:</div>
                        <div class="value">{{ project.industry.name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Вид продукции:</div>
                        <div class="value">{{ project.types_of_products }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Директор:</div>
                        <div class="value">{{ project.company_director_name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Телефон директора:</div>
                        <div class="value"><a :href="`tel:${project.company_phone}`">{{ project.company_phone }}</a></div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Иностранный инвестор:</div>
                        <div class="value">{{ project.foreign_investor_info || 'Нет' }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Мощность проекта: </div>
                        <div class="value">{{ project.project_capacity }} <span v-if="project.measure_unit" class="lowercase">тыс. {{ project.measure_unit.name }} в год</span></div>
                    </div>
                    <div v-if="project.date_start" class="block_list__item">
                        <div class="name">Дата начала проекта:</div>
                        <div class="value">{{ $moment(project.date_start).format('DD MMMM YYYY') }}г.</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Планируемый срок ввода в эксплуатацию (месяц, год):</div>
                        <div class="value">{{ $moment(project.dead_line).format('DD MMMM YYYY') }}г.</div>
                    </div>
                </div>
            </div>
            <div class="aside__block">
                <div class="aside_label">Стоимость проекта</div>
                <PriceChart :item="project" graphColor="#eff2f5" :useBackground="false" />
                <div v-if="project.fin_institute" class="block_list mt-3">
                    <div class="block_list__item">
                        <div class="name">Финансовый институт:</div>
                        <div class="value">{{ project.fin_institute }}</div>
                    </div>
                </div>
            </div>
            <div class="aside__block">
                <div class="aside_label">Этап реализации</div>
                <div class="progress">
                    <div class="progress_label">Стадия СМР, %</div>
                    <div class="progress_input" :class="Number(project.installation_stage) === 100 && 'success'">
                        <div class="progress_input__active" :style="`width: ${Number(project.installation_stage)}%;`">
                            <div class="badge">{{ project.installation_stage }}%</div>
                        </div>
                    </div>
                </div>
                <div class="block_list">
                    <div class="block_list__item">
                        <div class="name">Проведенные инфраструктуры:</div>
                        <div class="value">{{ project.infrastructure_info }}</div>
                    </div>
                </div>
            </div>
            <div class="aside__block">
                <div class="aside_label">Закуп оборудования</div>
                <div 
                    v-for="equipment in project.equipment_purchases" 
                    :key="equipment.id" 
                    class="block_list equipment">
                    <div class="equipment_subtitle">Источник закупа</div>
                    <div v-if="equipment.country" class="block_list__item">
                        <div class="name">Страна закупа оборудования:</div>
                        <div class="value">{{ equipment.country.name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Наименование планируемого поставщика:</div>
                        <div class="value">{{ equipment.supplier_name }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Сроки поставки оборудования: </div>
                        <div class="value">{{ $moment(equipment.delivery_date).format('DD MMMM YYYY') }}г.</div>
                    </div>
                </div>
            </div>
            <div class="aside__block">
                <div class="aside_label">Дополнительная информация</div>
                <div class="block_list">
                    <div class="block_list__item">
                        <div class="name">Временные рабочие места:</div>
                        <div class="value">{{ jobLabel(project.jobs_temporary) }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Постоянные рабочие места:</div>
                        <div class="value">{{ jobLabel(project.jobs_permanent) }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Пастбища, тыс. га: </div>
                        <div class="value">{{ Math.round(project.pasture_quantity) }} га.</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Пашни, тыс. га:</div>
                        <div class="value">{{ Math.round(project.plowed_field_quantity) }} га</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Земельный участок:</div>
                        <div class="value">{{ Math.round(project.land_plot) }} га</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Кадастровый номер ЗУ: </div>
                        <div class="value">{{ project.cadaster }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Опыт работы: </div>
                        <div class="value">{{ project.work_experience || 'Нет' }}</div>
                    </div>
                    <div class="block_list__item">
                        <div class="name">Проблемные вопросы: </div>
                        <div class="value">{{ project.questions || 'Нет' }}</div>
                    </div>
                </div>
            </div>
        </template>
        <a-skeleton v-else active />
    </div>
</template>

<script>
import PriceChart from './PriceChart.vue'
import { declOfNum } from '../utils.js'
export default {
    components: {
        PriceChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        project: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        jobLabel(num, showCount = true) {
            if(showCount) {
                return num + ' ' + declOfNum(num,
                    ['место', 'мест', 'мест'])
            } else {
                return declOfNum(num,
                    ['место', 'мест', 'мест'])
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.aside{
    background: #fff;
    padding: 15px;
    border-radius: var(--borderRadius);
    @media (min-width: 768px) {
        background: #eff2f5;
        padding: 20px;
    }
    @media (min-width: 1700px) {
        padding: 30px;
    }
    .aside_label{
        font-size: 20px;
        color: #000000;
        margin-bottom: 20px;
    }
    &__block{
        &:not(:last-child){
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #bfc2c4;
        }
    }
    .progress{
        margin-bottom: 20px;
        .progress_label{
            margin-bottom: 30px;
        }
        .progress_input{
            background: #ffffff;
            height: 8px;
            width: 100%;
            position: relative;
            border-radius: 8px;
            &__active{
                background: #1D65C0;
                border-radius: 8px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 0px;
                transition: all .3s cubic-bezier(.645,.045,.355,1);
                .badge{
                    position: absolute;
                    color: #000;
                    right: -7px;
                    top: -27px;
                }
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: -4px;
                    background: #1D65C0;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    transition: all .3s cubic-bezier(.645,.045,.355,1);
                }
            }
            &.success{
                .progress_input__active{
                    background: rgb(63, 134, 0);
                    &::after{
                        opacity: 0;
                    }
                }
            }
        }
    }
    .aside_status{
        &::v-deep{
            .ant-btn{
                cursor: default;
                margin-bottom: 15px;
                &:not(:last-child){
                    margin-right: 15px;
                }
            }
        }
    }
    .block_list{
        font-size: 14px;
        &.equipment{
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        .equipment_subtitle{
            font-size: 14px;
            color: #000;
            margin-bottom: 13px;
        }
        &__item{
            @media (min-width: 768px) {
                display: flex;
            }
            &:not(:last-child){
                margin-bottom: 14px;
            }
            .name{
                color: #000000;
                opacity: 0.6;
                word-break: break-word;
                @media (min-width: 768px) {
                    min-width: 210px;
                    max-width: 210px;
                    padding-right: 20px;
                }
                @media (min-width: 1200px) {
                    min-width: 260px;
                    max-width: 260px;
                }
            }
            .value{
                color: #000000;
                word-break: break-word;
            }
        }
    }
}
</style>